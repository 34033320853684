import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FooterMenuItems } from '../../FooterMenuItems';
import primaryLogoFooter from '../../images/logo-primary.svg';
import linkedinLogo from '../../images/icon-linked.svg';
import facebookLogo from '../../images/icon-fb.svg';
import globe from '../../images/globe-background.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MainMenuItemType } from '../molecules/MobileMenu';

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${({ theme }) => theme.paddingDesktop}; 
    padding-top: 145px;
    padding-bottom: 114px;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor}; 

    @media (max-width: 767.98px) {
        padding: ${({ theme }) => theme.paddingPhone}; 
        padding-top: 80px;
        padding-bottom: 50px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        padding: ${({ theme }) => theme.paddingTablet}; 
        padding-top: 66px;
        padding-bottom: 40px;
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        padding: ${({ theme }) => theme.paddingLaptop}; 
        padding-top: 110px;
        padding-bottom: 55px;
    }
`

const FooterContent = styled.div`
    display: flex;
    margin-top: 40px;
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 2;
    width: 100%;

    div:first-child span:first-child {
        font-weight: 600;
    }

    @media (max-width: 767.98px) {
        justify-content: flex-start;
    }
`

const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 20%;

    @media (max-width: 767.98px) {
        width: 100%;
        margin-bottom: 80px;
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 30%;
        margin-bottom: 80px;
    }
`

const StyledFooterMenuItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 80%;

    @media (max-width: 991.98px) {
        width: 100%;
        margin-bottom: 80px;
    }
    
`

const FooterSocialMediaWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin: 40px 0;

    a:first-child {
        margin-right: 23px;
    }
`

const FooterSpan = styled.span`
    font-family: Museo Sans;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.blackColor}; 
`

const FooterAnchor = styled.a`
    font-family: Museo Sans;
    text-decoration: none;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.blackColor};

    :hover {
        color: ${({ theme }) => theme.primaryColor};
    }
`

const FooterMenu = styled.ul`
   display: flex;
   flex-direction: column;
   width: 14.285%;
   margin: 0;
   list-style-type: none;

   li:first-child a {
    font-family: Museo;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.primaryColor};
    margin: 20px 0;
    text-decoration: none;
    :hover {
        color: ${({ theme }) => theme.blueColor};
    }

   }

   li {
        margin-bottom: 20px;
        list-style-type: none;
   }

   @media (min-width: 992px) and (max-width: 1199.98px) { 
        width: 25%;
        margin-bottom: 30px;
    }

   @media (max-width: 991.98px) {
        width: 50%;
        margin: 0 16px 40px -16px;

        li {
            border-bottom: 1px solid ${({ theme }) => theme.lightGreyColor};
            margin: 0 16px;
            padding: 16px 0;
        }
        li:first-child, li:last-child {
            border-bottom: none;
        }
    }
`

const FooterMenuItem = styled(Link)`
    font-family: Museo Sans;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.blackColor};
    margin: 20px 0;
    text-decoration: none;

    :hover {
        color: ${({ theme }) => theme.primaryColor};
    }

    @media (max-width: 767.98px) {
        margin: 0;
    }
`

const FooterBackgroundImg = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 70px;
    right: 140px;
    transform: rotate(-9.92deg);

    @media (min-width: 768px) and (max-width: 991.98px) {
        right: -30px;
        bottom: 10px;
    }

    @media (max-width: 767.98px) {
        right: -30px;
        bottom: 10px;
    }

    @media (min-width: 992px) and (max-width: 1600px) {
        right: 50px;
        bottom: 50px;
    }
`

const Logo = styled.img`
    width: 194px;
    height: 34px;
`;

const Media = styled.img`
    width: 24px;
    height: 25px;
`;

const Globe = styled.img`
    width: 880px;
    height: 400px;

    @media (max-width: 767.98px) {
        width: 100%;
        height: auto;
    }
`;

const Footer: React.FC = () => {

    const {t} = useTranslation();

    const FooterMenuItems: MainMenuItemType[] = [
        {
            name: t('tools.name'),
            url: "/tools/",
            subPages: [
                {
                    name: t('tools.lis'),
                    url: "/tools/lis/"
                }
                // {
                //     name: t('tools.source'),
                //     url: "/tools/source/"
                // },
                //{
                //    name: t('tools.harmony'),
                //    url: "/tools/harmony/"
                //}
            ]
        },
        {
            name: t('services.name'),
            url: "/uslugi/",
            subPages: [
                {
                    name:  t('services.transport'),
                    url: "/uslugi/transport/"
                },
                {
                    name: t('services.powerEngineering'),
                    url: "/uslugi/energetyka/"
                },
                {
                    name: t('services.environment'),
                    url: "/uslugi/srodowisko/"
                }
                //{
                //    name: t('services.realEstate'),
                //    url: "/uslugi/nieruchomosci/"
                //},
                //{
                //    name: t('services.drones'),
                //    url: "/uslugi/drony/"
                //},
            ]
        },
        {
            name: t('aboutUs'),
            url: "/o-nas/"
        },
        {
            name: t('career.title'),
            url: "/kariera/"
        },
        {
            name: t('news'),
            url: "/aktualnosci/"
        },
        {
            name: t('contact.title'),
            url: "/kontakt/"
        }
    ]


    return (
        <StyledFooter>
            <Link to="/">
                <Logo src={primaryLogoFooter} alt="Databout logo w stopce" />
            </Link>
            <FooterContent>
                <FooterColumn>
                    <FooterSpan>Databout sp. z o.o.</FooterSpan>
                    <FooterSpan>ul. Bitwy Warszawskiej 1920&nbsp;r./7</FooterSpan>
                    <FooterSpan>02-366 Warszawa</FooterSpan>
                    <br/>
                    <FooterAnchor href="fax:+48224927113">Tel: +48 515 285 776</FooterAnchor>
                    <FooterAnchor href="mailto:kontakt@databout.pl">kontakt@databout.pl</FooterAnchor>
                    <FooterSocialMediaWrapper>
                        <FooterAnchor href="https://www.linkedin.com/company/databout/">
                            <Media src={linkedinLogo} alt="linked" />
                        </FooterAnchor>  
                        <FooterAnchor href="https://www.facebook.com/Databoutpl-110919137293892">
                            <Media src={facebookLogo} alt="fb" />
                        </FooterAnchor>
                    </FooterSocialMediaWrapper>
                    <FooterSpan>© {new Date().getFullYear()} Databout sp. z o.o. All rights reserved</FooterSpan>
                    <FooterAnchor href="https://www.facebook.com/furyartstudio">Designed by Furyarts Studio</FooterAnchor>
                </FooterColumn>
                <StyledFooterMenuItems>
                    {
                        FooterMenuItems.map((menuItem, index) => 
                        <FooterMenu key={index}>
                            <li><FooterMenuItem to={menuItem.url}>{menuItem.name}</FooterMenuItem></li>
                            {
                                menuItem.subPages && menuItem.subPages.map((subpage, index) => <li key={index}><FooterMenuItem to={subpage.url} key={subpage.name}>{subpage.name}</FooterMenuItem></li>)
                            }
                        </FooterMenu>)
                    }
                    <FooterMenu>
                        <li><a href="/polityka-prywatnosci.pdf" target="_blank">{t('privacyPolicy')}</a></li>
                    </FooterMenu>                   
                </StyledFooterMenuItems>
            </FooterContent>
            <FooterBackgroundImg>
                <Globe src={globe} alt="globe"/>
            </FooterBackgroundImg>
        </StyledFooter>
    )
}

export default Footer;
